<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h1>
          Welkom bij Humanwise!
        </h1>
      </v-col>

      <v-col cols="12" class="mb-4">
        Heb je al een account? Log dan hier in
      </v-col>

      <v-col cols="12" class="bm-4">
        <form v-on:submit.prevent="" v-on:keyup.enter="submit">
          <v-text-field
              v-model="form.email"
              label="Email"
              required
          ></v-text-field>

          <v-text-field
              v-model="form.password"
              label="Wachtwoord"
              type="password"
              required
          ></v-text-field>

          <a @click="forgotPassword" class="float-left">Wachtwoord vergeten?</a>

          <v-btn color="accent" class="mr-4" block @click="submit" :loading="loading" :disabled="loading">Login</v-btn>
        </form>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="mb-1">
        Nog geen account bij Humanwise maar wel een scan gemaakt? Vraag er hier een aan!
      </v-col>
      <v-col cols="12" class="mb-1">
        <v-btn color="accent" class="mr-4" @click="createAccount">Account aanvragen</v-btn>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="mb-1">
        Heb je nog geen Humanwise profiel? Vraag dan de benodigde informatie aan.
      </v-col>
      <v-col cols="12" class="mb-4">
        <v-btn color="accent" class="mr-4" @click="informationRequest">Informatie aanvragen</v-btn>
      </v-col>

      <snackbar-text :text="error.message" bg-color="red" text-color="white" v-model="error.show" />
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import SnackbarText from "@/components/snackbar-text";

export default {
  name: "Login",
  components: {SnackbarText},
  data: () => ({
    form: {
      email: null,
      password: null,
    },
    error: {
      show: false,
      message: ""
    },
    loading: false,
  }),
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    submit() {
      this.loading = true;

      this.signIn(this.form).then(() => {
        this.$router.push({name: 'dashboardHome'})
      }).catch((err) => {
        this.error.message = "Inloggen mislukt, controller je emailadres en wachtwoord.";

        this.error.show = true;
        this.loading = false;
      });
    },
    createAccount() {
      this.$router.push({name: 'register'})
    },
    informationRequest() {
      this.$router.push({name: 'informationRequest'})
    },
    forgotPassword() {
      this.$router.push({name: 'forgotPassword'})
    }
  },
};
</script>
